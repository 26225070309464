import {offerStatusTypes} from '~/models/offer-status-type';

/**
 * Навигация для раздела "Цифровой оффер"
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
	const store = useDnsTech();

	if (!store.offerData) {
		await store.fetchOfferData(String(to.params.id));
	}

	const guid   = computed(() => store.offerData?.offerGuid);
	const status = computed(() => store.offerData?.status);

	switch (status.value) {
		case offerStatusTypes.ACTIVE:
			if (to.path.includes('accept') || to.path.includes('feedback')) {
				return navigateTo(`/offer/${guid.value}`);
			}

			break;
		case offerStatusTypes.ACCEPTED:
			if (!to.path.includes('accept')) {
				return navigateTo(`/offer/${guid.value}/accept`)
			}

			break;
		case offerStatusTypes.REJECTED:
			if (!to.path.includes('feedback')) {
				return navigateTo(`/offer/${guid.value}/feedback`)
			}

			break;
		default:
			return abortNavigation();
	}
})
